* {
    /* font-family: 'Ephesis', cursive; */
/* font-family: 'Grenze Gotisch', serif; */
font-family: 'Poppins', sans-serif;
/* font-family: 'Whisper', cursive; */
}

.hero {
    
    font-family: 'Grenze Gotisch', serif;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url("../assets/images/cofee.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 180px;
}

.hero h1 {
    font-family: 'Whisper', cursive;
    font-size: 70px;
    font-weight: 800;
    color: rgb(255, 81, 0);
    text-shadow: 1px 2px 1px rgb(253, 253, 253);
}
.hero p {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    /* font-weight: 800; */
}
.navbar {
    padding: 7px 0 7px 0;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform-origin: top;
    transition:all 0.2s ease;
    -webkit-transform: all 0.2s ease;
    -ms-transform: all 0.2s ease;
    -o-transform: all 0.2s ease;


}
.navbar.color-active {
    background-color: rgba(31, 12, 1, 0.878);
    color:rgb(255, 123, 7);
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    padding: 10px 0 10px 0;
}
.gambar {
    
    height: 60px;
}
.gallery img {
    height: 220px;
    width: 350px;
}
.isiTable {
    font-family: system-ui, -apple-system, 'Afacad', sans-serif;
    font-size: small;
}
